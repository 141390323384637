import React from "react";
import styles from "./SelectInput.module.scss";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

function SelectInput(props) {
  return (
    <div className={styles.selectInputContainer}>
      <label>{props.label}</label>

      <div className={styles.outerInput}>
        <select {...props}>
          {/* <option selected>Choose a country</option> */}
          {props.options.map((option) => {
            return (
              <option selected={option.selected} value={option.value} key={option.value}>
                {option.label}
              </option>
            );
          })}

          {props.errorMessage && <ExclamationCircleIcon className={styles.errorIcon} />}
          {props.successMessage && <CheckCircleIcon className={styles.successIcon} />}
          {/* <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option> */}
        </select>
      </div>
    </div>
  );
}

export default SelectInput;

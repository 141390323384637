import React from "react";
import styles from "./Button.module.scss";

function Button(props) {
  return (
    <a className={styles.button} {...props}>
      {props.children}
    </a>
  );
}

export default Button;

import React from "react";
import styles from "./AddressContainer.module.scss";
import DefaultInput from "../../Inputs/DefaultInput/DefaultInput";
import Button from "../../Buttons/Button/Button";
import { ADDRESS_TYPES } from "../../../assets/enums/addressTypes";
import SelectInput from "../../Inputs/SelectInput/SelectInput";
import DefaultContainer, { DefaultContainerTitle } from "../DefaultContainer/DefaultContainer";

function AddressContainer({
  addressType,
  onAddressTypeChange,
  firstname,
  lastname,
  email,
  onFirstnameChange,
  onLastnameChange,
  onEmailChange,
  onFirstnameError,
  onLastnameError,
  onEmailError,
  // invoice address
  comapnyName,
  street,
  streetNumber,
  city,
  zipCode,
  country,
  onCompanyNameChange,
  onStreetChange,
  onStreetNumberChange,
  onCityChange,
  onZipCodeChange,
  onCountryChange,
  onStreetError,
  onStreetNumberError,
  onCityError,
  onZipCodeError,
  onCountryError,
  countries,
}) {
  return (
    <div>
      <DefaultContainerTitle>Anschrift</DefaultContainerTitle>
      <DefaultContainer>
        {/* <p className={styles.title}>TeilnehmerIn</p> */}

        <form>
          {addressType === ADDRESS_TYPES.INVOICE && (
            <div style={{ marginBottom: "1em" }}>
              <DefaultInput
                for=""
                type="text"
                label="Firma (Optional)"
                value={comapnyName}
                onChange={(e) => onCompanyNameChange(e.target.value)}
              />
            </div>
          )}
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "1em", marginBottom: "1em" }}>
            <DefaultInput
              type="text"
              name="firstname"
              label="Vorname"
              placeholder="Max"
              value={firstname}
              errorMessage={onFirstnameError}
              onChange={(e) => onFirstnameChange(e.target.value)}
            />
            <DefaultInput
              type="text"
              name="lastname"
              label="Nachname"
              placeholder="Mustermann"
              value={lastname}
              errorMessage={onLastnameError}
              onChange={(e) => onLastnameChange(e.target.value)}
            />
          </div>
          <DefaultInput
            type="email"
            name="email"
            label="E-Mail"
            placeholder="max@gmail.com"
            value={email}
            errorMessage={onEmailError}
            onChange={(e) => onEmailChange(e.target.value)}
          />
          {addressType === ADDRESS_TYPES.INVOICE && (
            <>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "80%  17%",
                  columnGap: "1em",
                  marginBottom: "1em",
                  marginTop: "1em",
                }}
              >
                <DefaultInput
                  type="text"
                  name="street"
                  label="Straße"
                  value={street}
                  errorMessage={onStreetError}
                  onChange={(e) => onStreetChange(e.target.value)}
                />
                <DefaultInput
                  type="text"
                  name="streetNumber"
                  label="Hausnr."
                  value={streetNumber}
                  errorMessage={onStreetNumberError}
                  onChange={(e) => onStreetNumberChange(e.target.value)}
                />
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "30% 67%", columnGap: "1em", marginBottom: "1em" }}>
                <DefaultInput
                  type="text"
                  name="zipCode"
                  label="PLZ"
                  value={zipCode}
                  errorMessage={onZipCodeError}
                  onChange={(e) => onZipCodeChange(e.target.value)}
                />
                <DefaultInput
                  type="text"
                  name="city"
                  label="Ort"
                  value={city}
                  errorMessage={onCityError}
                  onChange={(e) => onCityChange(e.target.value)}
                />
              </div>

              <SelectInput
                label="Land"
                options={countries}
                value={country}
                onChange={(e) => onCountryChange(e.target.value)}
              />
            </>
          )}
          <div style={{ marginTop: "1em" }}>
            <Button onClick={onAddressTypeChange}>
              {addressType === ADDRESS_TYPES.DEFAULT ? `Rechnungsadresse hinzufügen?` : `Rechnungsadresse entfernen`}
            </Button>
          </div>

          {/* <div class="flex items-start mb-6">
      <div class="flex items-center h-5">
        <input
          id="remember"
          type="checkbox"
          value=""
          class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
          required
        />
      </div>
      <label for="remember" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400">
        Ich stimme zu und verlange ausdrücklich, dass Sie vor Ablauf der Widerrufsfrist mit der Ausführung des
        Vertrages beginnen. Mir ist bekannt, dass ich dadurch bei Downloadprodukten und digitalen Inhalten mit
        Beginn der Ausführung des Vertrages und bei Dienstleistungen bei vollständiger Erfüllung des Vertrages mein{" "}
        <a href="#" class="text-blue-600 hover:underline dark:text-blue-500">
          Widerrufsrecht
        </a>{" "}
        verliere. I agree with the{" "}
        <a href="#" class="text-blue-600 hover:underline dark:text-blue-500">
          terms and conditions
        </a>
        .
      </label>
    </div> */}
        </form>
      </DefaultContainer>
    </div>
  );
}

export default AddressContainer;

import React, { useState } from "react";
import styles from "./priceSummaryContainer.module.scss";
import { formatCurrency } from "../../../utils/formatter";
import DefaultInput from "../../Inputs/DefaultInput/DefaultInput";
import Button from "../../Buttons/Button/Button";

function PriceSummaryContainer({
  price,
  tax,
  promotionCode,
  onPromotionCodeChange,
  promotionCodeError,
  calculateTotalPrice,
  calculateUnitPrice,
}) {
  const [showDiscountInput, setShowDiscountInput] = useState(false);

  return (
    <div className={styles.checkoutPriceSummaryContainer}>
      {/* <div className={styles.inlineContainer}>
        <p className={styles.priceTitle}>Gesamt</p>
        <span className={styles.priceValue}>{formatCurrency(calculateTotalPrice(), price.currency)}</span>
      </div> */}
      <div className={styles.divider} />
      <div className={styles.inlineContainer}>
        <p className={styles.priceAddidtionText}>1x Teilnehmer</p>
        <span className={styles.priceAddidtionValue}>{formatCurrency(calculateUnitPrice(), price.currency)}</span>
        {/* <span className={styles.priceAddidtionValue}>1x</span> */}
      </div>
      {promotionCode && (
        <div className={styles.inlineContainer}>
          <p className={styles.priceAddidtionText}>{`Rabbat${
            promotionCode.coupon.percentOff ? ` (${promotionCode.coupon.percentOff}%)` : ""
          }`}</p>
          <span className={styles.priceAddidtionValue}>{`-${formatCurrency(
            promotionCode.coupon.unitAmountOff,
            price.currency
          )}`}</span>
        </div>
      )}
      <div className={styles.inlineContainer}>
        <p className={styles.priceAddidtionText}>{`MwSt (${Math.round(tax?.percentageDecimal)}%)`}</p>
        <span className={styles.priceAddidtionValue}>{formatCurrency(tax?.unitAmount, tax?.currency)}</span>
      </div>

      <div className={styles.inlineContainer}>
        <p className={styles.priceAddidtionText}>Zertifikat</p>
        <span className={styles.priceAddidtionValue}>KOSTENLOS</span>
      </div>

      <div className={styles.divider} style={{ marginBottom: "0.5em" }} />
      <div className={styles.inlineContainer}>
        <p className={styles.priceAddidtionText}>Gesamt</p>
        <span className={styles.priceAddidtionValue}>{formatCurrency(calculateTotalPrice(), price.currency)}</span>
        {/* <span className={styles.priceAddidtionValue}>1x</span> */}
      </div>

      <div style={{ marginBottom: "0.5em" }}>
        {showDiscountInput ? (
          <>
            {/* <div style={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}> */}
            <DefaultInput
              label="Gutscheincode"
              type="text"
              placeholder="xxxx-xxxx-xxxx-xxxx"
              backgroundColor="white"
              style={{ backgroundColor: "white" }}
              onChange={(e) => onPromotionCodeChange(e.target.value)}
              errorMessage={promotionCodeError}
              successMessage={promotionCode && "Gutscheincode erfolgreich angewendet!"}
            />
            {/* <a className="">Anwenden</a> */}
            {/* </div> */}
          </>
        ) : (
          <div className={styles.inlineContainer}>
            <Button onClick={() => setShowDiscountInput(true)}> Du hast einen Gutschein?</Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PriceSummaryContainer;

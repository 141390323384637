import React, { useEffect } from "react";
import "./index.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Routes, Route } from "react-router-dom";
import CheckoutView from "./views/CheckoutView/CheckoutView";
import { Toaster } from "react-hot-toast";
import countries from "i18n-iso-countries";
import config from "./config/config";
import Footer from "./components/Footer/Footer";

// // Make sure to call `loadStripe` outside of a component's render to avoid
// // recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.STRIPE.PK);

function App() {
  useEffect(() => {
    countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
  }, []);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Elements stripe={stripePromise}>
        <Routes>
          <Route path="/:productId" element={<CheckoutView />} />
        </Routes>
      </Elements>
    </>
  );
}

export default App;

import React from "react";
import styles from "./ProductInfoContainer.module.scss";
import DefaultContainer, { DefaultContainerTitle } from "../DefaultContainer/DefaultContainer";
import { TiStarFullOutline, TiStarHalf } from "react-icons/ti";
import PriceSummaryContainer from "../PriceSummaryContainer/PriceSummaryContainer";

function ProductInfoContainer({ product }) {
  return (
    <div>
      {/* <p className={styles.title}>{product?.courseType}</p> */}
      {/* <a className={styles.productName}>{product.name}</a> */}
      {/* <p className={styles.description}>
      Das eine Kind braucht Sicherheit, das andere Herausforderungen und das Dritte viel Aufmerksamkeit: In dieser
      Webinar-Aufzeichnung stellt dir Virginie Puschmann die Marte Meo® Methode vor, mit der du die emotionale
      Stabilität der Kinder stärkst und gleichzeitig deine eigenen Stärken gezielter einsetzt, um ihnen in ihrer
      Entwicklung zu helfen.
    </p> */}
      <div className={styles.productInfo}>
        <img src={product.image} className={styles.productImage} draggable={false} />
        <div className={styles.textContainer}>
          <p className={styles.title}>{product.name}</p>
          {/* <p className={styles.description}>
      Das eine Kind braucht Sicherheit, das andere Herausforderungen und das Dritte viel Aufmerksamkeit: In dieser
      Webinar-Aufzeichnung stellt dir Virginie Puschmann die Marte Meo® Methode vor, mit der du die emotionale
      Stabilität der Kinder stärkst und gleichzeitig deine eigenen Stärken gezielter einsetzt, um ihnen in ihrer
      Entwicklung zu helfen.
    </p> */}
          {/* <p className={styles.description}>3 Module • 64 Lektionen • 2 Stunden Dauer</p> */}
        </div>

        <div className={styles.socialProofContainer}>
          <div className={styles.employeesImages}>
            <img
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
              draggable={false}
            />
            <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png" draggable={false} />
            <img
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
              draggable={false}
            />
            <img
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/thomas-lean.png"
              draggable={false}
            />
          </div>
          <div className={styles.rating}>
            <div className={styles.stars}>
              <TiStarFullOutline />
              <TiStarFullOutline />
              <TiStarFullOutline />
              <TiStarFullOutline />
              <TiStarHalf />
            </div>

            <div className={styles.peopleNumber}>Absolviert von 1.000+ Teilnehmern</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductInfoContainer;

import React from "react";
import styles from "./defaultContainer.module.scss";

function DefaultContainer({ children, padding = "1em" }) {
  return (
    <div className={styles.container} style={{ padding }}>
      {children}
    </div>
  );
}

export default DefaultContainer;

export const DefaultContainerTitle = ({ children }) => {
  return <p className={styles.title}>{children}</p>;
};

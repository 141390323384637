import React from "react";
import styles from "./PrimaryButton.module.scss";
import classNames from "classnames";
import "./spinner.css";

function PrimaryButton(props) {
  return (
    <a className={classNames(styles.primaryButton, props.isLoading && styles.isLoading)} {...props}>
      {props.isLoading ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        props.children
      )}
    </a>
  );
}

export default PrimaryButton;

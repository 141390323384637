import React, { useEffect, useRef } from "react";
import styles from "./paymentFinishedView.module.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import countries from "i18n-iso-countries";
import config from "../../config/config";
import { formatCurrency } from "../../utils/formatter";
import Header from "../../components/Containers/Header/Header";
import CenterdViewContainer from "../../components/Containers/CenterdViewContainer/CenterdViewContainer";
import { useParams } from "react-router-dom";

function PaymentFinishedView({ paymentIntent, email }) {
  const { productId } = useParams();
  const lottieAnimationRef = useRef();
  const status = paymentIntent?.status;
  const bankTransferInstructions = paymentIntent?.next_action?.display_bank_transfer_instructions;

  useEffect(() => {
    setTimeout(() => {
      lottieAnimationRef.current?.play();
    }, 500);
  }, []);

  const getLottieAnimationSource = () => {
    if (status === "succeeded") {
      return "https://assets4.lottiefiles.com/packages/lf20_rc5d0f61.json";
    } else if (status === "processing" || status === "requires_action") {
      return "https://lottie.host/c1ee0f67-7bd5-4346-9a2a-aedf9cd48d65/l1XIAZAEM3.json";
    } else {
      return "https://lottie.host/03b18660-6c21-4ba0-9c2e-4899dcdd8e8e/7SOgPONcNh.json";
    }
  };

  const getEmailProviderUrl = () => {
    const provider = email.split("@")[1];

    if (provider === "gmail.com") {
      return "https://mail.google.com/mail/u/0/#inbox";
    } else if (provider === "outlook.com") {
      return "https://outlook.live.com/mail/0/inbox";
    } else if (provider === "yahoo.com") {
      return "https://mail.yahoo.com/d/folders/1";
    } else if (provider === "icloud.com") {
      return "https://www.icloud.com/#mail";
    } else if (provider === "aol.com") {
      return "https://mail.aol.com/webmail-std/en-us/suite";
    } else if (provider === "gmx.de") {
      return "https://www.gmx.net/mail/";
    } else if (provider === "web.de") {
      return "https://web.de/";
    } else if (provider === "mail.com") {
      return "https://www.mail.com/mail/";
    } else if (provider === "t-online.de") {
      return "https://email.t-online.de/";
    } else if (provider === "freenet.de") {
      return "https://email.freenet.de/";
    } else if (provider === "protonmail.com") {
      return "https://mail.protonmail.com/inbox";
    } else if (provider === "posteo.de") {
      return "https://posteo.de/";
    } else if (provider === "gmx.net") {
      return "https://www.gmx.net/mail/";
    } else if (provider === "gmx.at") {
      return "https://www.gmx.net/mail/";
    } else if (provider === "gmx.ch") {
      return "https://www.gmx.net/mail/";
    } else {
      return "";
    }
  };

  return (
    <CenterdViewContainer>
      <div className={styles.paymentFinishedView}>
        <Header />
        <div className={styles.inner}>
          <Player
            keepLastFrame={true}
            autoplay={false}
            loop={status === "processing" || status === "requires_action"}
            ref={lottieAnimationRef}
            src={getLottieAnimationSource()}
            style={{ height: "8em", width: "8em" }}
          />

          {status === "succeeded" && <h1 className={styles.title}>{`Zahlung erfolgreich!`}</h1>}
          {(status === "processing" || status === "requires_action") && (
            <h1 className={styles.title}>{`Vorgang erfolgreich!`}</h1>
          )}
          {status !== "processing" && status !== "succeeded" && status !== "requires_action" && (
            <h1 className={styles.title}>{`Vorgang fehlgeschlagen!`}</h1>
          )}
          {status === "processing" && (
            <p className={styles.description}>
              Sobald der Zahlungseingang bei uns verbucht wurde, schicken wir dir eine E-Mail an{" "}
              <a href={getEmailProviderUrl()} target="_blank" rel="noopener noreferrer">
                {email}
              </a>{" "}
              mit deinen Zugangsdaten zur Kursplattform.
            </p>
          )}
          {status === "succeeded" && (
            <p className={styles.description}>
              Vielen Dank für dein Vertrauen in Praxis-Kita. Wir haben dir eine E-Mail an{" "}
              <a href={getEmailProviderUrl()} target="_blank" rel="noopener noreferrer">
                {email}
              </a>{" "}
              mit deinen Zugangsdaten zur Kursplattform geschickt.
            </p>
          )}

          {status !== "processing" && status !== "succeeded" && status !== "requires_action" && (
            <p className={styles.description}>
              {/* Tut uns leid, es sieht so aus als ob etwas Fehlgeschlagen ist! Bitte versuche die Fortbildung erneut zu
              kaufen. */}
              Wir bedauern dir mitteilen zu müssen, dass deine Zahlung fehlgeschlagen ist, bitte versuche das Produkt{" "}
              <a href={`/${productId}`}>erneut zu bestellen</a>, sollte das Problem weiterhin bestehen, kontaktiere
              bitte den <a href={`mailto:${config.SUPPORT_EMAIL}`}>Kundensupport!</a>
            </p>
          )}
          {status === "requires_action" &&
            paymentIntent?.next_action?.type === "display_bank_transfer_instructions" &&
            bankTransferInstructions && (
              <>
                <p className={styles.description} style={{ textAlign: "left" }}>
                  Vielen Dank für dein Vertrauen in Praxis-Kita. Du erhältst den Zugang zu deiner Fortbildung, sobald
                  der komplette Überweisungsbetrag in Höhe von{" "}
                  <b>{formatCurrency(bankTransferInstructions.amount_remaining, bankTransferInstructions.currency)}</b>{" "}
                  bei uns eingegangen ist.
                </p>
                <p className={styles.subTitle}>Bankinformationen:</p>
                <div className={styles.bankTransferInstructions}>
                  <div className={styles.inlineItem}>
                    <label>BIC:</label>
                    <span>{bankTransferInstructions.financial_addresses[0]?.iban?.bic}</span>
                  </div>
                  <div className={styles.inlineItem}>
                    <label>IBAN:</label>
                    <span>{bankTransferInstructions.financial_addresses[0]?.iban?.iban}</span>
                  </div>
                  <div className={styles.inlineItem}>
                    <label>Land:</label>
                    <span>
                      {countries.getName(bankTransferInstructions.financial_addresses[0]?.iban?.country, "de")}
                    </span>
                  </div>

                  <div className={styles.inlineItem}>
                    <label>Empfänger:</label>
                    <span>{bankTransferInstructions.financial_addresses[0]?.iban?.account_holder_name}</span>
                  </div>
                  <div className={styles.inlineItem}>
                    <label>Verwendungszweck:</label>
                    <span>{bankTransferInstructions.reference}</span>
                  </div>
                </div>
                <p className={styles.description} style={{ textAlign: "left", marginTop: "1em" }}>
                  Wir haben dir eine Rechnung an <a href={getEmailProviderUrl()}>{email}</a> gesendet!
                </p>
                {/* <Button>Rechnung herunterladen</Button> */}
              </>
            )}

          {/* <div className={styles.bottomButtons}>
            <PrimaryButton>Zur Kursplatform</PrimaryButton>
            <PrimaryButton>Rechnung Herunterladen</PrimaryButton>
          </div> */}
        </div>
      </div>
    </CenterdViewContainer>
  );
}

export default PaymentFinishedView;

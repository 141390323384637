import React, { useEffect, useRef } from "react";
import styles from "./productNotFoundView.module.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import config from "../../config/config";

function ProductNotFoundView() {
  const lottieAnimationRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      lottieAnimationRef.current?.play();
    }, 800);
  }, []);

  return (
    <div className={styles.productNotFoundView}>
      <Player
        keepLastFrame={true}
        autoplay={false}
        loop={true}
        ref={lottieAnimationRef}
        src={"https://lottie.host/03b18660-6c21-4ba0-9c2e-4899dcdd8e8e/7SOgPONcNh.json"}
        style={{ height: "8em", width: "8em" }}
      />
      <p className={styles.title}>Das Produkt konnte nicht gefunden werden!</p>
      <div style={{ maxWidth: "50em" }}>
        <p className={styles.description}>
          Etwas ist schiefgelaufen und das Produkt konnte nicht gefunden werden, bitte versuche es später erneut! Sollte
          das Problem weiterhin bestehen kontaktiere bitte den{" "}
          <a href={`mailto:${config.SUPPORT_EMAIL}`}>Kundensupport</a>, wir helfen dir gerne weiter!
        </p>
      </div>
    </div>
  );
}

export default ProductNotFoundView;

import React, { useEffect, useState } from "react";
import { CardElement, IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import styles from "./checkoutview.module.scss";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import countries from "i18n-iso-countries";

// api
import { retrieveProduct } from "../../api/product";
import { validatePromotionCode } from "../../api/coupon";
import { calculateTax } from "../../api/tax";

// container
import PaymentMethodSelectorContainer from "../../components/Containers/PaymentMethodSelectorContainer/PaymentMethodSelectorContainer";
import PriceSummaryContainer from "../../components/Containers/PriceSummaryContainer/PriceSummaryContainer";
import AddressContainer from "../../components/Containers/AddressContainer/AddressContainer";
import ProductInfoContainer from "../../components/Containers/ProductInfoContainer/ProductInfoContainer";

// payment methods images
import paypal from "./../../assets/images/paymentMethods/paypal.png";
import sepa from "./../../assets/images/paymentMethods/sepa.png";
import sofort from "./../../assets/images/paymentMethods/sofort.png";
import vorkasse from "./../../assets/images/paymentMethods/vorkasse.png";
import creditcard from "./../../assets/images/paymentMethods/creditcard.png";

// utils
import { validateEmail } from "../../utils/validation";
import { getClientLocale } from "../../utils/localization";
import { confirmPayment } from "../../utils/payment";
import { formatCurrency } from "../../utils/formatter";

// enums
import { ADDRESS_TYPES } from "../../assets/enums/addressTypes";
import { PAYMENT_METHODS } from "../../assets/enums/paymentMethods";
import { createPaymentIntent } from "../../api/payment";

import LoadingView from "../LoadingView/LoadingView";
import ProductNotFoundView from "../ProductNotFoundView/ProductNotFoundView";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Containers/Header/Header";
import DefaultContainer, { DefaultContainerTitle } from "../../components/Containers/DefaultContainer/DefaultContainer";
import PaymentFinishedView from "../PaymentFinishedView/PaymentFinishedView";
import ReactGA from "react-ga4";

const DEFAULT_PAYMENT_METHODS = [
  {
    id: PAYMENT_METHODS.PAYPAL,
    name: "PayPal",
    img: paypal,
    isSelected: false,
  },
  {
    id: PAYMENT_METHODS.SEPA_DEBIT,
    name: "SEPA Lastschrift",
    img: sepa,
    isSelected: false,
  },
  {
    id: PAYMENT_METHODS.CARD,
    name: "Kredit/debit Karte",
    isSelected: false,
    img: creditcard,
  },
  // {
  //   id: PAYMENT_METHODS.SOFORT,
  //   name: "Sofort Überweisung",
  //   isSelected: false,
  //   img: sofort,
  // },
  {
    id: PAYMENT_METHODS.BANK_TRANSFER,
    name: "Vorkasse",
    isSelected: false,
    img: vorkasse,
  },
];

function CheckoutView() {
  const { productId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [paymentMethods, setPaymentMethods] = useState(DEFAULT_PAYMENT_METHODS);
  const [sepaAccountHolderName, setSepaAccountHolderName] = useState("");
  const [promotionCode, setPromotionCode] = useState(null);
  const [local, setLocal] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  // product infos
  const [product, setProduct] = useState(null);
  const [price, setPrice] = useState(null);
  const [tax, setTax] = useState(null);

  // loading states
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  // address
  const [addressType, setAddressType] = useState(ADDRESS_TYPES.DEFAULT);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");

  // invoice address
  const [companyName, setCompanyName] = useState("");
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("DE");

  // modals
  const [showPaymentFinishedModal, setShowPaymentFinishedModal] = useState(false);

  // errors
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [paymentMethodError, setPaymentMethodError] = useState(null);
  const [sepaAccountHolderNameError, setSepaAccountHolderNameError] = useState(null);
  const [promotionCodeError, setPromotionCodeError] = useState(null);

  // invoice errors
  const [StreetError, setStreetError] = useState(null);
  const [StreetNumberError, setStreetNumberError] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [zipCodeError, setZipCodeError] = useState(null);

  // payment intent
  const [paymentIntent, setPaymentIntent] = useState(null);

  // promotion code timeout
  var promotionCodeInputTimeout = 0;

  useEffect(() => {
    fetchLocal();
    fetchProduct();
  }, []);

  // useEffect(() => {
  //   fetchProduct();
  // }, [local]);

  useEffect(() => {
    const clientSecret = searchParams.get("payment_intent_client_secret");
    if (!stripe || !clientSecret) return;

    stripe.retrievePaymentIntent(clientSecret).then((res) => {
      setEmail(searchParams.get("email"));
      setPaymentIntent(res.paymentIntent);
      setShowPaymentFinishedModal(true);
    });
  }, [stripe]);

  const fetchLocal = async () => {
    await getClientLocale()
      .then((local) => {
        setLocal(local);
        console.log(local);
        setCountry(local.loc);
      })
      .catch((error) => {
        toast.error(error.response.data.meta.msg || "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support.");
      });
  };

  const fetchProduct = async () => {
    setIsLoading(true);

    // if (!local) return;

    await retrieveProduct({ productId, ipAddress: "" })
      .then((response) => {
        setProduct(response.data.product);
        setPrice(response.data.price);
        setTax(response.data.tax);
        // set document title
        document.title = response?.data?.product?.name || "";
        ReactGA.send({ hitType: "pageview", page: `/${productId}`, title: response.data.product?.name });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.meta.msg || "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support.");
      });

    setIsLoading(false);
  };

  const fetchCalculatedTax = async ({ promotionCode }) => {
    await calculateTax({ productId, promotionCode, ipAddress: "" })
      .then((response) => {
        setTax(response.data.data.tax);
      })
      .catch((error) => {
        toast.error(error.response.data.meta.msg || "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support.");
      });
  };

  const getSelectedPaymentMethod = () => {
    return paymentMethods.find((method) => method.isSelected);
  };

  const handlePaymentMethodSelect = (paymentMethod) => {
    // ########
    setPaymentMethodError(null);
    // ########

    setPaymentMethods((prevState) => {
      return prevState.map((method) => {
        if (method.name === paymentMethod.name) {
          return {
            ...method,
            isSelected: true,
          };
        }
        return {
          ...method,
          isSelected: false,
        };
      });
    });

    if (paymentMethod.id === PAYMENT_METHODS.BANK_TRANSFER) {
      setAddressType(ADDRESS_TYPES.INVOICE);
    }

    if (
      getSelectedPaymentMethod()?.id === PAYMENT_METHODS.BANK_TRANSFER &&
      paymentMethod.id !== PAYMENT_METHODS.BANK_TRANSFER
    ) {
      if (!companyName || !street || !streetNumber || !zipCode || !city) {
        setAddressType(ADDRESS_TYPES.DEFAULT);
      }
    }
  };

  const validateInputValues = ({ selectedPaymentMethod }) => {
    if (isProcessingPayment) return false;
    if (!stripe) {
      toast.error("Stripe is not loaded!");
      return false;
    }

    var isValid = true;

    if (!firstname || !lastname || !email || !validateEmail(email) || !selectedPaymentMethod) isValid = false;
    if (selectedPaymentMethod?.id === PAYMENT_METHODS.SEPA_DEBIT && sepaAccountHolderName.length === 0) isValid = false;
    if (addressType === ADDRESS_TYPES.INVOICE) {
      if (!streetNumber || !street || !zipCode || !city) isValid = false;
    }

    if (!selectedPaymentMethod) setPaymentMethodError("Bitte wähle eine Zahlungsmethode aus!");
    if (!validateEmail(email)) setEmailError("Bitte gib eine gültige E-Mail Adresse ein!");
    if (!firstname) setFirstNameError("Bitte gib deinen Vornamen ein!");
    if (!lastname) setLastNameError("Bitte gib deinen Nachnamen ein!");
    if (!email) setEmailError("Bitte gib deine E-Mail Adresse ein!");
    if (selectedPaymentMethod?.id === PAYMENT_METHODS.SEPA_DEBIT && sepaAccountHolderName.length === 0)
      setSepaAccountHolderNameError("Bitte gib den Namen des Kontoinhabers ein!");

    // company errors
    if (addressType === ADDRESS_TYPES.INVOICE) {
      if (!street) setStreetError("Bitte gib deine Straße ein!");
      if (!streetNumber) setStreetNumberError("Bitte gib deine Hausnummer ein!");
      if (!zipCode) setZipCodeError("Bitte gib deine Postleitzahl ein!");
      if (!city) setCityError("Bitte gib deine Stadt ein!");
    }

    return isValid;
  };

  const handlePayButtonClick = async () => {
    const selectedPaymentMethod = getSelectedPaymentMethod();

    resetErrorMessages();
    if (!validateInputValues({ selectedPaymentMethod })) return;

    setIsProcessingPayment(true);

    // get language
    // const language = navigator.language.includes("-") ? navigator.language : `${navigator.language}-${local.loc}`;

    await createPaymentIntent({
      product: productId,
      email,
      firstname,
      lastname,
      // locale: {
      //   language,
      //   country: local.loc,
      // },
      address: {
        company: companyName,
        city: city,
        country: country,
        street: street,
        streetNumber: streetNumber,
        zipCode: zipCode,
      },
      invoice: addressType === ADDRESS_TYPES.INVOICE,
      promotionCode: promotionCode?.code,
      paymentMethod: selectedPaymentMethod?.id,
    })
      .then(async (response) => {
        if (selectedPaymentMethod?.id === PAYMENT_METHODS.BANK_TRANSFER) {
          setPaymentIntent(response.data.data.paymentIntent);
          setShowPaymentFinishedModal(true);
          setIsProcessingPayment(false);
          return;
        }

        await confirmPayment({
          stripe,
          stripeClientSecret: response.data.data.stripeClientSecret,
          productId,
          firstname,
          lastname,
          email,
          country,
          // country: local.loc,
          paymentMethod: selectedPaymentMethod?.id,
          sepaAccountHolderName: sepaAccountHolderName,
          ibanElement: elements.getElement(IbanElement),
          cardElement: elements.getElement(CardElement),
        })
          .then((paymentIntent) => {
            setPaymentIntent(paymentIntent);
            setShowPaymentFinishedModal(true);
          })
          .catch((error) => {
            setPaymentMethodError(error.message);
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.response.data.meta.msg || "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support.");
      });

    setIsProcessingPayment(false);
  };

  const resetErrorMessages = () => {
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setPaymentMethodError(null);
    setSepaAccountHolderNameError(null);

    // company
    setStreetError(null);
    setStreetNumberError(null);
    setZipCodeError(null);
    setCityError(null);
  };

  const handlePromotionCodeChange = async (promotionCode) => {
    if (!promotionCode) {
      setPromotionCode(null);
      return;
    }

    if (promotionCodeInputTimeout) clearTimeout(promotionCodeInputTimeout);
    promotionCodeInputTimeout = setTimeout(async () => {
      // validate promtion code
      await validatePromotionCode({ promotionCode, productId, courseParticipants: 1 })
        .then((response) => {
          setPromotionCodeError(null);
          setPromotionCode(response.data.data);
          fetchCalculatedTax({ promotionCode });
        })
        .catch((error) => {
          setPromotionCode(null);
          setPromotionCodeError(
            error.response.data.meta.msg || "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support."
          );
        });
    }, 600);
  };

  const calculateTotalPrice = () => {
    const totalPrice = promotionCode
      ? price.unitAmount - promotionCode.coupon.unitAmountOff < 0
        ? 0
        : price.unitAmount - promotionCode.coupon.unitAmountOff
      : price.unitAmount;
    return totalPrice <= 0 ? 50 : totalPrice;
  };

  const calculateUnitPrice = () => {
    const unitPrice = price?.unitAmount - tax?.unitAmount - (promotionCode ? promotionCode?.coupon?.unitAmountOff : 0);
    return unitPrice > 0 ? unitPrice : 0;
  };

  const handleAddressTypeChange = () => {
    setAddressType(addressType === ADDRESS_TYPES.DEFAULT ? ADDRESS_TYPES.INVOICE : ADDRESS_TYPES.DEFAULT);
  };

  const getCountries = () => {
    return Object.entries(countries.getNames("de", { select: "official" })).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  };

  if (isLoading) return <LoadingView />;

  if (!product) return <ProductNotFoundView />;

  if (showPaymentFinishedModal) return <PaymentFinishedView email={email} paymentIntent={paymentIntent} />;

  return (
    <>
      <div className={styles.checkoutView}>
        <Header />

        <div className={styles.gridContainer}>
          <div className={styles.orderDetailsContainer_mobile}>
            <DefaultContainerTitle>Bestelldetails</DefaultContainerTitle>
            <DefaultContainer padding="0">
              <ProductInfoContainer product={product} />

              <PriceSummaryContainer
                price={price}
                tax={tax}
                promotionCode={promotionCode}
                promotionCodeError={promotionCodeError}
                onPromotionCodeChange={handlePromotionCodeChange}
                calculateTotalPrice={calculateTotalPrice}
                calculateUnitPrice={calculateUnitPrice}
              />
            </DefaultContainer>
          </div>
          <div className={styles.leftContainerGrid}>
            <AddressContainer
              addressType={addressType}
              onAddressTypeChange={handleAddressTypeChange}
              firstname={firstname}
              lastname={lastname}
              email={email}
              onFirstnameChange={setFirstname}
              onLastnameChange={setLastname}
              onEmailChange={setEmail}
              onFirstnameError={firstNameError}
              onLastnameError={lastNameError}
              onEmailError={emailError}
              // company address
              companyName={companyName}
              street={street}
              streetNumber={streetNumber}
              city={city}
              zipCode={zipCode}
              country={country}
              onCompanyNameChange={setCompanyName}
              onStreetChange={setStreet}
              onStreetNumberChange={setStreetNumber}
              onCityChange={setCity}
              onZipCodeChange={setZipCode}
              onCountryChange={setCountry}
              onStreetError={StreetError}
              onStreetNumberError={StreetNumberError}
              onCityError={cityError}
              onZipCodeError={zipCodeError}
              countries={getCountries()}
            />
            <PaymentMethodSelectorContainer
              paymentMethods={paymentMethods}
              onPaymentMethodSelect={handlePaymentMethodSelect}
              selectedPaymentMethod={getSelectedPaymentMethod()}
              onSepaAccountHolderNameChange={setSepaAccountHolderName}
              sepaAccountHolderNameError={sepaAccountHolderNameError}
              sepaAccountHolderName={sepaAccountHolderName}
              paymentMethodErrorMessage={paymentMethodError}
            />
          </div>
          <div style={{ position: "relative" }}>
            <div className={styles.rightContainerGrid}>
              <div className={styles.orderDetailsContainer}>
                <DefaultContainerTitle>Bestelldetails</DefaultContainerTitle>
                <DefaultContainer padding="0">
                  <ProductInfoContainer product={product} />

                  <PriceSummaryContainer
                    price={price}
                    tax={tax}
                    promotionCode={promotionCode}
                    promotionCodeError={promotionCodeError}
                    onPromotionCodeChange={handlePromotionCodeChange}
                    calculateTotalPrice={calculateTotalPrice}
                    calculateUnitPrice={calculateUnitPrice}
                  />
                </DefaultContainer>
              </div>

              <div className={styles.payButtonContainer}>
                <PrimaryButton
                  onClick={handlePayButtonClick}
                  isLoading={isProcessingPayment}
                >{`Bezahlen ${formatCurrency(calculateTotalPrice(), price.currency)}`}</PrimaryButton>

                <p className={styles.description} style={{ marginTop: "1em" }}>
                  Durch Klicken auf "Bezahlen" stimmst Du den{" "}
                  <a href="https://praxis-kita.com/agb/" target="_blank" rel="noopener noreferrer">
                    Allgemeinen Geschäftsbedingungen
                  </a>{" "}
                  zu.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default CheckoutView;

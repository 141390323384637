import CreditCardPaymentForm from "../../Forms/PaymentMethodsForms/CreditCardPaymentForm";
import SEPAPaymentForm from "../../Forms/PaymentMethodsForms/SEPAPaymentForm";
import styles from "./PaymentMethodSelectorContainer.module.scss";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import DefaultContainer, { DefaultContainerTitle } from "../DefaultContainer/DefaultContainer";

function PaymentMethodSelectorContainer({
  paymentMethods,
  onPaymentMethodSelect,
  selectedPaymentMethod,
  sepaAccountHolderName,
  sepaAccountHolderNameError,
  onSepaAccountHolderNameChange,
  paymentMethodErrorMessage,
}) {
  const getPaymentOptionsContainer = (paymentMethod) => {
    if (paymentMethod.name === "PayPal") {
      return (
        <div className={styles.redirectText}>
          Du wirst anschließend zu PayPal weitergeleitet, um deine Zahlung abzuschließen.
        </div>
      );
    } else if (paymentMethod.name === "SEPA Lastschrift") {
      return (
        <SEPAPaymentForm
          accountHolderName={sepaAccountHolderName}
          onSepaAccountHolderNameChange={onSepaAccountHolderNameChange}
          sepaAccountHolderNameError={sepaAccountHolderNameError}
        />
      );
    } else if (paymentMethod.name === "Kredit/debit Karte") {
      return <CreditCardPaymentForm />;
    } else if (paymentMethod.name === "Sofort Überweisung") {
      return (
        <div className={styles.redirectText}>
          Du wirst anschließend zu Sofort Überweisung weitergeleitet, um deine Zahlung abzuschließen.
        </div>
      );
    }
  };

  return (
    <div>
      <DefaultContainerTitle>Bezahlmethode</DefaultContainerTitle>
      <DefaultContainer>
        {/* <div className={classNames(styles.container, paymentMethodErrorMessage && styles.containerError)}> */}
        <div className={styles.paymentMethods}>
          {paymentMethods.map((paymentMethod) => {
            return (
              <PaymentMethodItem
                paymentMethod={paymentMethod}
                onClick={() => onPaymentMethodSelect(paymentMethod)}
                paymentOptionsContainer={getPaymentOptionsContainer(paymentMethod)}
              />
            );
          })}
        </div>
        <div></div>
        {paymentMethodErrorMessage && (
          <div className={styles.paymentMethodErrorContainer}>
            <ExclamationCircleIcon className={styles.errorIcon} />
            <p className={styles.paymentMethodErrorMessage}>{paymentMethodErrorMessage}</p>
          </div>
        )}
        {/* </div> */}
      </DefaultContainer>
    </div>
  );
}

export default PaymentMethodSelectorContainer;

function PaymentMethodItem({ paymentMethod, onClick, paymentOptionsContainer }) {
  return (
    <div>
      <div
        className={classNames(styles.paymentMethodItem, paymentMethod.isSelected && styles.isSelected)}
        onClick={onClick}
      >
        <div className={styles.leftContainer}>
          <input checked={paymentMethod.isSelected} type="radio" className={styles.radioInput} />
          <p>{paymentMethod.name}</p>
        </div>
        <img draggable={false} src={paymentMethod.img} alt={paymentMethod.name} />
      </div>
      {paymentMethod.isSelected && (
        <AnimatePresence initial={true}>
          <motion.div key="modal" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 1 }}>
            <div className={styles.paymentOptionsContainer}>{paymentOptionsContainer}</div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./Styles.css";
import styles from "./CreditCardPaymentForm.module.scss";

export default function CreditCardPaymentForm() {
  return (
    <form>
      <label className={styles.label}>Karten Details</label>
      <CardElement options={{ hidePostalCode: true }} />
    </form>
  );
}

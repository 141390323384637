import React from "react";
import styles from "./DefaultInput.module.scss";
import classNames from "classnames";
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/24/outline";

function DefaultInput(props) {
  return (
    <div className={styles.defaultInputContainer}>
      <label>{props.label}</label>
      <div
        className={classNames(
          styles.input,
          props.errorMessage && styles.inputError,
          props.successMessage && styles.inputSuccess
        )}
        style={{ backgroundColor: props.backgroundColor }}
      >
        <input {...props} />
        {props.errorMessage && <ExclamationCircleIcon className={styles.errorIcon} />}
        {props.successMessage && <CheckCircleIcon className={styles.successIcon} />}
      </div>
      {props.errorMessage && <p className={styles.errorMessage}>{props.errorMessage}</p>}
      {props.successMessage && <p className={styles.successMessage}>{props.successMessage}</p>}
    </div>
  );
}

export default DefaultInput;

import React from "react";
import styles from "./footer.module.scss";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <a href="https://praxis-kita.com/">© Praxis-Kita {currentYear}</a>
      {/* <a href="https://praxis-kita.com/agb/">AGB</a> */}
      <a href="https://praxis-kita.com/impressum/">Imperessum</a>
      <a href="https://praxis-kita.com/datenschutz/">Datenschutzerklärung</a>
    </footer>
  );
}

export default Footer;

import React from "react";
import styles from "./header.module.scss";
import { XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../../assets/images/logo.jpg";

function Header() {
  return (
    <header className={styles.header}>
      <img src={logo} alt="Praxis-Kita" draggable={false} />

      <a href="https://praxis-kita.com/" className={styles.cancelButton}>
        <XMarkIcon className={styles.icon} />
        Abbrechen
      </a>
    </header>
  );
}

export default Header;

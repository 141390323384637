import { createPaymentIntent } from "../api/payment";
import { PAYMENT_METHODS } from "../assets/enums/paymentMethods";
import config from "../config/config";

export const createAndConfirmPaymentIntent = async ({
  email,
  firstname,
  lastname,
  locale,
  address,
  invoice,
  promotionCode,
  productId,
  paymentMethod,
}) => {
  return new Promise(async (resolve, reject) => {
    await createPaymentIntent({
      email,
      firstname,
      lastname,
      locale,
      address,
      invoice,
      promotionCode,
      product: productId,
      paymentMethod,
    })
      .then(resolve)
      .catch(reject);

    return;
  });
};

export const confirmPayment = async ({
  stripe,
  stripeClientSecret,
  productId,
  paymentMethod,
  email,
  firstname,
  lastname,
  country,
  ibanElement,
  cardElement,
  sepaAccountHolderName,
}) => {
  return new Promise(async (resolve, reject) => {
    if (paymentMethod === PAYMENT_METHODS.PAYPAL) {
      const { error, paymentIntent } = await stripe.confirmPayPalPayment(stripeClientSecret, {
        return_url: `${config.APP_URL}/${productId}?email=${email}`,
      });
      if (error) return reject(error);
      resolve(paymentIntent);
    } else if (paymentMethod === PAYMENT_METHODS.SOFORT) {
      const { error, paymentIntent } = await stripe.confirmSofortPayment(stripeClientSecret, {
        payment_method: {
          sofort: {
            country: country,
          },
          billing_details: {
            name: `${firstname} ${lastname}`,
          },
        },
        return_url: `${config.APP_URL}/${productId}?email=${email}`,
      });
      if (error) return reject(error);
      resolve(paymentIntent);
    } else if (paymentMethod === PAYMENT_METHODS.SEPA_DEBIT) {
      const { error, paymentIntent } = await stripe.confirmSepaDebitPayment(stripeClientSecret, {
        payment_method: {
          sepa_debit: ibanElement,
          billing_details: {
            name: sepaAccountHolderName,
            email,
          },
        },
      });
      if (error) return reject(error);
      resolve(paymentIntent);
    } else if (paymentMethod === PAYMENT_METHODS.CARD) {
      const { error, paymentIntent } = await stripe.confirmCardPayment(stripeClientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${firstname} ${lastname}`,
            email,
          },
        },
      });
      if (error) return reject(error);
      resolve(paymentIntent);
    } else if (paymentMethod === PAYMENT_METHODS.BANK_TRANSFER) {
      const { error } = await stripe.confirmPayment({
        clientSecret: stripeClientSecret,
        confirmParams: {
          return_url: `${config.APP_URL}/${productId}?email=${email}`,
        },
      });
      if (error) reject(error);
      resolve();
    }
  });
};

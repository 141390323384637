import axios from "axios";
// https://geo.privacymanager.io/

export const getClientLocale = () => {
  return new Promise((resolve, reject) => {
    // resolve({
    //   loc: "DE",
    //   ip: "87.142.149.134",
    // });
    // return;
    axios
      .get("https://www.cloudflare.com/cdn-cgi/trace")
      .then((data) => {
        const res = data.data
          .trim()
          .split("\n")
          .reduce(function (obj, pair) {
            pair = pair.split("=");
            return (obj[pair[0]] = pair[1]), obj;
          }, {});
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

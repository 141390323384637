import React from "react";
import { IbanElement } from "@stripe/react-stripe-js";
import DefaultInput from "../../Inputs/DefaultInput/DefaultInput";
import styles from "./CreditCardPaymentForm.module.scss";

const IBAN_STYLE = {
  base: {
    // backgroundColor: "red",
    color: "#32325d",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
    ":-webkit-autofill": {
      color: "#32325d",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    ":-webkit-autofill": {
      color: "#fa755a",
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: "DE",
  style: IBAN_STYLE,
};

export default function SEPAPaymentForm({
  sepaAccountHolderName,
  onSepaAccountHolderNameChange,
  sepaAccountHolderNameError,
}) {
  return (
    <form>
      <div class="mb-[1em]">
        <DefaultInput
          label="Name des Kontoinhabers"
          type="text"
          value={sepaAccountHolderName}
          errorMessage={sepaAccountHolderNameError}
          onChange={(e) => onSepaAccountHolderNameChange(e.target.value)}
        />
      </div>

      <div className="form-row">
        <label className={styles.label}>IBAN</label>
        <IbanElement options={IBAN_ELEMENT_OPTIONS} />
      </div>

      {/* Display mandate acceptance text. */}
      {/* <div className="mandate-acceptance text-xs text-slate-500">
        Durch Angabe Ihrer Zahlungsinformationen und der Bestätigung der vorliegenden Zahlung ermächtigen Sie (A)
        Praxis-Kita und Stripe, unseren Zahlungsdienstleister, bzw. PPRO, den lokalen Bankdienstleister von Stripe,
        Ihrem Kreditinstitut Anweisungen zur Belastung Ihres Kontos zu erteilen, und (B) Ihr Kreditinstitut, Ihr Konto
        gemäß diesen Anweisungen zu belasten. Im Rahmen Ihrer Rechte haben Sie, entsprechend den Vertragsbedingungen mit
        Ihrem Kreditinstitut, Anspruch auf eine Rückerstattung von Ihrem Kreditinstitut. Eine Rückerstattung muss
        innerhalb von 8 Wochen ab dem Tag, an dem Ihr Konto belastet wurde, geltend gemacht werden. Eine Erläuterung
        Ihrer Rechte können Sie von Ihrem Kreditinstitut anfordern. Sie erklären sich einverstanden, Benachrichtigungen
        über künftige Belastungen bis spätestens 2 Tage vor dem Buchungsdatum zu erhalten.
      </div> */}
    </form>
  );
}
